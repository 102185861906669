import React, { useState } from "react";
import Api from "@api";
import Button from "@atom/Button";
import InputBox from "@atom/InputBox";
import style from "./style.scss";
import cn from "classnames";

const Login = ({ setLoggedIn, setAdminToken }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [invalid, setInvalid] = useState(false);

  const adminLogin = () => {
    setInvalid(false);

    Api.login(username, password)
      .then((e) => {
        if (e) {
          setLoggedIn(true);
          localStorage.setItem("admin_token", e.token);
          setAdminToken(e.token);
        } else setInvalid(true);
      })
      .catch((_e) => {
        console.log("Login Failed");
      });
  };

  return (
    <div className={cn(style.form, style.login)}>
      <form>
        <InputBox
          name={"username"}
          label={"Username"}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder={"Admin Username"}
        />
        <InputBox
          name={"password"}
          label={"Password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder={"Admin Password"}
          type={"password"}
        />
        <div className={cn(style.invalid, invalid && style.show)}>
          Invalid Username or Password!
        </div>
        <Button onClick={adminLogin}>Login</Button>
      </form>
    </div>
  );
};

export default Login;
