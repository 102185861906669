import React, { useState } from "react";
import DownArrow from "@assets/double-arrow.svg";
import UpArrow from "@assets/double-up.svg";
import ProgressiveImage from "@atom/ProgressiveImage";
import style from "./style.scss";
import cn from "classnames";

export default ({ blendguideImage }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <label onClick={() => setIsOpen(!isOpen)}>
        Blendguide
        {isOpen ? <UpArrow /> : <DownArrow />}
      </label>
      <div className={cn(style.collapsible, isOpen && style.active)}>
        <ProgressiveImage
          {...blendguideImage}
          className={cn(style.blendguideimg)}
        />
      </div>
    </>
  );
};
