import React from "react";
import style from "./style.scss";
import cn from "classnames";

export default ({
  className,
  name,
  label,
  id,
  placeholder,
  type = "text",
  onChange,
  // value = "",
  withError,
  errorMessage,
  filename,
  ...rest
}) => (
  <div className={cn(style.inputBox, className)}>
    {label && (
      <label className={cn(style.label)} htmlFor={id}>
        {label}
      </label>
    )}
    <input {...{ name, id, placeholder, onChange, type, ...rest }} />
    {type === "file" && (
      <div className={cn(style.imgLabel)}>
        <label htmlFor={id}>{"Select file"}</label>
        <span>{filename}</span>
      </div>
    )}
    <div className={cn(style.invalid, withError && style.show)}>
      {errorMessage}
    </div>
  </div>
);
