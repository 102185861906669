import React from "react";
import style from "./style.scss";
import cn from "classnames";

export default ({
  className,
  href = "#",
  target = "_blank",
  download,
  children,
  ...rest
}) => (
  <a
    className={cn(style.anchor, className)}
    target={href !== "#" ? target : null}
    {...{ href, download }}
    {...rest}
  >
    {children}
  </a>
);
