import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import BG from "@assets/comingsoon.svg";
import Menu from "@molecule/Menu";
import Loading from "@atom/Loading";
import ProgressiveImage from "@atom/ProgressiveImage";
import Admin from "./Admin";
import Home from "./Home";
import Packs from "./Packs";
import data from "./data";
import style from "./style.scss";
import cn from "classnames";

const Screen = () => {
  const finalLabels = data.navigation.menu;

  return (
    <div className={cn(style.container)}>
      <ProgressiveImage {...data.background} className={cn(style.bg)} />
      {data.navigation.menu ? (
        <div className={cn(style.content)}>
          <Menu navi={finalLabels} socials={data.socials} />
          <Switch>
            <Route exact path={"/"} component={() => <Home />} />
            <Route
              path={"/packs"}
              component={() => <Packs {...data.navigation} />}
            />
            <Route path={"/comingsoon"}>
              <div className={cn(style.comingsoon)}>
                <BG />
              </div>
            </Route>
            <Route
              path={"/binx-content-admin"}
              component={() => <Admin {...data.navigation} />}
            />
            <Redirect to={"/"} />
          </Switch>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Screen;
