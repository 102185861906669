import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Link } from "react-router-dom";
import DeleteIcon from "@assets/delete.svg";
import EditIcon from "@assets/edit.svg";
import Heading from "@atom/Heading";
import ProgressiveImage from "@atom/ProgressiveImage";
import style from "./style.scss";
import cn from "classnames";

export default ({ deletePack, id, title, background, art }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const styleDND = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const url = "/binx-content-admin/past-packs/";

  return (
    <div
      className={cn(style.pack)}
      style={styleDND}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      <div
        className={cn(style.artContainer)}
        style={{
          backgroundImage: `url(${background && background.src})`,
        }}
      >
        <ProgressiveImage {...art} />
      </div>
      <div className={cn(style.rightSide)}>
        <Heading type={"h5"} className={cn(style.title)}>
          {title}
        </Heading>
        <div className={cn(style.buttons)}>
          <Link to={url + id}>
            <EditIcon />
          </Link>
          <DeleteIcon onClick={() => deletePack(id)} />
        </div>
      </div>
    </div>
  );
};
