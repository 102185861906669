import React, { useEffect, useState } from "react";
import Api from "@api";
import Loading from "@atom/Loading";
import Banner from "@molecule/Banner";
import BannerLinks from "@molecule/BannerLinks";
import Footer from "@molecule/Footer";
import { pastpackLinks } from "./data";
import style from "./style.scss";
import cn from "classnames";

const PastPacks = ({ footer, menu }) => {
  const [packs, setPacks] = useState();
  const [featured, setFeatured] = useState({});

  useEffect(() => {
    Api.getFeatured().then((e) => setFeatured(e));
    Api.getPastPacks().then((e) => setPacks(e));
  }, []);

  return (
    <div className={cn(style.pastPacks)}>
      <div className={cn(style.links)}>
        <BannerLinks links={pastpackLinks} pack={true} />
      </div>
      {packs && featured ? (
        <>
          <Banner {...featured} className={cn(style.banner)} pack={true} />

          {packs.map((value, i) => (
            <Banner
              {...value}
              mobileArt={value.mobileart}
              key={i}
              className={cn(style.banner)}
              pack={true}
            />
          ))}
        </>
      ) : (
        <Loading />
      )}
      <Footer {...footer} navi={menu} />
    </div>
  );
};

export default PastPacks;
