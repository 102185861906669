import React from "react";
import Anchor from "../Anchor";
import Heading from "../Heading";
import style from "./style.scss";
import cn from "classnames";

const LabeledIcon = ({ icon, text, subtext, smaller }) => (
  <div className={cn(style.labeledIcon, smaller && style.smaller)}>
    <img {...icon} alt={text} />
    <div className={cn(style.text)}>
      <Heading type={"h4"} className={cn(style.h4)}>
        {text}
      </Heading>
      {subtext ? <label>{subtext}</label> : null}
    </div>
  </div>
);

export default ({ icon, link, text, subtext, smaller }) => (
  <Anchor href={link}>
    <LabeledIcon {...{ icon, text, subtext, smaller }} />
  </Anchor>
);
