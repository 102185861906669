import React from "react";
import scss from "./style.scss";
import cn from "classnames";

export default ({ type, uppercase, className, weight, children, onClick }) => (
  <div
    className={cn(
      scss.default,
      type === "h2"
        ? scss.h2
        : type === "h3"
        ? scss.h3
        : type === "h4"
        ? scss.h4
        : type === "h5"
        ? scss.h5
        : scss.h1,
      uppercase && scss.uppercase,
      className
    )}
    style={{ fontWeight: weight }}
    {...{ onClick }}
  >
    {children}
  </div>
);
