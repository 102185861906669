import React from "react";
import { Link, useLocation } from "react-router-dom";
import Anchor from "@atom/Anchor";
import style from "./style.scss";
import cn from "classnames";

export const Navigation = ({ binxLogo, menuLabels, footer }) => {
  let location = useLocation().pathname;

  return (
    <div className={cn(style.navigation, footer && style.footer)}>
      <Link to={"/"}>
        <img {...binxLogo} />
      </Link>
      <div style={{ width: 24 }}></div>
      <div className={cn(style.text)}>
        {menuLabels.map(({ label, route, link, target }, i) =>
          route ? (
            <Link key={i} to={route}>
              <label
                className={cn(style.label, location === route && style.active)}
              >
                {label}
              </label>
            </Link>
          ) : (
            <Anchor
              key={i}
              href={link}
              target={target}
              className={cn(style.label)}
            >
              {label}
            </Anchor>
          )
        )}
      </div>
    </div>
  );
};

export default ({ navi, socials = [] }) => (
  <div className={cn(style.heading)}>
    <Navigation {...navi} />
    <div className={cn(style.iconGroup)}>
      {socials.map(({ icon, aria, link }, i) => (
        <Anchor key={i} href={link} rel="noopener">
          {React.createElement(icon, { "aria-label": aria })}
        </Anchor>
      ))}
    </div>
  </div>
);
