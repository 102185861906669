import React, { useEffect, useState } from "react";
import Resizer from "react-image-file-resizer";
import { useHistory } from "react-router-dom";
import Api from "@api";
import LoadingSVG from "@assets/loading.svg";
import placeholderURL from "@assets/noimg.png";
import Button from "@atom/Button";
import InputBox from "@atom/InputBox";
import style from "./style.scss";
import cn from "classnames";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      180,
      100,
      "png",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

const PackForm = ({
  featured,
  savePack,
  formValues = [],
  loading,
  setLoading,
}) => {
  let history = useHistory();
  const [formInput, setFormInput] = useState(formValues);
  const [imgPreviews, setImgPreviews] = useState([]);

  useEffect(() => {
    setFormInput(formValues);
    if (formValues === null) setFormInput([]);
  }, [formValues]);

  const saveSinglePack = async (e) => {
    e.preventDefault();
    setLoading(true);
    const adminToken = localStorage.getItem("admin_token");
    const formData = new FormData();
    let finalInput = {};
    const keys = Object.keys(formInput);

    for (let index = 0; index < keys.length; index++) {
      const name = keys[index];

      if (
        name === "background" ||
        name === "art" ||
        name === "mobileart" ||
        name === "blendguide"
      ) {
        formData.append(`src.${name}`, formInput[name].src);
        formData.append(`placeholder.${name}`, formInput[name].placeholder);
        finalInput[name] = formInput[name];
      } else if (featured && name == "enddate") {
        finalInput["enddate"] = formInput["enddate"].toLocaleString("en-US", {
          timeZone: "America/Los_Angeles",
        });
      } else {
        finalInput[name] = formInput[name];
      }
    }
    if (imgPreviews.length !== 0) {
      await Api.uploadImages(formData)
        .then((e) => {
          if (e) {
            for (let key in e) {
              const imgAlt = { ["alt"]: `${formInput.title} ${key}` };
              finalInput[key] = Object.assign(finalInput[key], e[key], imgAlt);
            }
          } else alert("Upload failed \n Error: " + e.message);
        })
        .catch((_e) => {
          alert("Upload failed");
        });
    }

    savePack(finalInput, adminToken);
  };

  const handleChange = (e, name) => {
    setFormInput((prevState) => ({
      ...prevState,
      [name]: e.target.value,
    }));
  };

  const handleImageChange = async (e, name) => {
    try {
      let reader = new FileReader();

      const file = e.target.files[0];
      const placeholder = await resizeFile(file);

      reader.onloadend = () => {
        let image = new Image();
        image.src = reader.result;
        image.onload = () => {
          setFormInput((prevState) => ({
            ...prevState,
            [name]: {
              ...prevState[name],
              ["src"]: file,
              ["placeholder"]: placeholder,
              ["width"]: image.width,
              ["height"]: image.height,
            },
          }));
        };

        setImgPreviews((prevState) => ({
          ...prevState,
          [name]: reader.result,
        }));
      };

      if (name === "blendguide") {
        setFormInput((prevState) => ({
          ...prevState,
          ["withblendguide"]: true,
        }));
      }

      reader.readAsDataURL(file);
    } catch (err) {
      console.log("File placholder not created!:", err);
    }
  };

  let inputsImg = [
    { name: "background", label: "Background URL" },
    { name: "art", label: "Art URL" },
    { name: "blendguide", label: "Blendguide" },
  ];

  if (featured) {
    inputsImg.splice(2, 0, { name: "mobileart", label: "Mobile Art URL" });
  }

  return (
    <div className={cn(style.form)}>
      <form
        name={featured ? "FeaturedForm" : "PastPack"}
        onSubmit={(e) => saveSinglePack(e)}
      >
        <InputBox
          name={"title"}
          label={"Title"}
          value={formInput["title"] || ""}
          onChange={(e) => handleChange(e, "title")}
          required
        />
        {featured && (
          <InputBox
            name={"url"}
            label={"Pack URL"}
            value={formInput["url"] || ""}
            onChange={(e) => handleChange(e, "url")}
          />
        )}
        {featured && (
          <InputBox
            type="datetime-local"
            name={"enddate"}
            label={"Countdown End Date"}
            value={formInput["enddate"] || ""}
            onChange={(e) => handleChange(e, "enddate")}
            min={new Date()}
            max={"2022-12-31"}
            required
          />
        )}
        <input type="hidden" id="timezone" name="timezone" value="-08:00" />
        {inputsImg.map(({ name, label }, i) => (
          <div key={i} className={cn(style.imgInput)}>
            <img
              src={
                imgPreviews[name]
                  ? imgPreviews[name]
                  : formInput[name] && formInput[name].src
                  ? formInput[name].src
                  : placeholderURL
              }
              width={140}
            />
            <InputBox
              style={{ display: "none" }}
              type={"file"}
              accept={"image/png, image/jpeg"}
              id={name + i}
              name={name + i}
              label={label}
              onChange={(e) => handleImageChange(e, name)}
              required={
                name == "blendguide"
                  ? false
                  : imgPreviews[name] ||
                    (formInput[name] && formInput[name].src)
                  ? false
                  : true
              }
              filename={formInput[name] && formInput[name].src.name}
            />
          </div>
        ))}
        <div className={cn(style.withBlend)}>
          <input
            type="checkbox"
            name="withblendguide"
            checked={formInput["withblendguide"] ? "checked" : ""}
            disabled={!(formInput["blendguide"] && formInput["blendguide"].src)}
            onChange={(e) =>
              setFormInput((prevState) => ({
                ...prevState,
                ["withblendguide"]: e.target.checked,
              }))
            }
          />
          <label htmlFor="withBlendguide"> Show Blendguide</label>
        </div>
        <div className={cn(style.buttons)}>
          <Button type="submit" value="Submit" disabled={loading}>
            {loading ? <LoadingSVG /> : "Save"}
          </Button>
          {featured ? (
            <Button onClick={() => setFormInput(formValues)}>Reset</Button>
          ) : (
            <Button
              onClick={() => history.push("/binx-content-admin/past-packs")}
            >
              Cancel
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default PackForm;
