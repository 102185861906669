import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Api from "@api";
import MoveIcon from "@assets/move.svg";
import Button from "@atom/Button";
import Loading from "@atom/Loading";
import PackForm from "@molecule/PackForm";
import style from "./style.scss";
import cn from "classnames";

const FeaturedPack = () => {
  const [featured, setFeatured] = useState([]);
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  useEffect(() => {
    Api.getFeatured().then((e) => setFeatured(e));
  }, []);

  const saveFeatured = (formData, adminToken) => {
    Api.updateFeaturedPack(formData, adminToken)
      .then((e) => {
        if (e) {
          setFeatured(e);
          alert("Featured pack saved!");
        } else alert("Featured pack update failed! \n Error: " + e.message);
        setLoading(false);
      })
      .catch((_e) => {
        alert("Featured pack update failed!");
        setLoading(false);
      });
  };

  const movePack = async () => {
    const adminToken = localStorage.getItem("admin_token");
    let newPack = featured;
    delete newPack.mobileart;
    delete newPack.url;
    delete newPack.enddate;

    await Api.addPastPack(newPack, adminToken).then((e) => {
      if (e) {
        alert("Pack Added!");
        history.push("/binx-content-admin/past-packs");
      } else alert("Pack not added! \n Error: " + e.message);
    });
  };

  return (
    <div className={cn(style.featuredPack)}>
      {featured.length !== 0 ? (
        <>
          <div className={cn(style.movePack)}>
            <Button className={cn(style.move)} onClick={movePack}>
              <MoveIcon />
              <span>Move to Past Packs</span>
            </Button>
          </div>
          <PackForm
            featured={true}
            savePack={saveFeatured}
            formValues={featured}
            loading={loading}
            setLoading={setLoading}
          />
        </>
      ) : (
        <Loading form />
      )}
    </div>
  );
};

export default FeaturedPack;
