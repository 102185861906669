import React, { useEffect } from "react";
import Anchor from "@atom/Anchor";
import ProgressiveImage from "@atom/ProgressiveImage";
import atomicLogo from "@assets/atomic-hub-logo.png";
import BannerLinks from "../BannerLinks";
import BlendGuide from "../Blendguide";
import style from "./style.scss";
import cn from "classnames";

const logo = {
  src: atomicLogo,
  alt: "Atomic Hub Logo",
  width: 309,
  height: 94,
};

export default ({
  background,
  art,
  mobileart,
  links,
  className,
  pack,
  blendguide,
  withblendguide,
  currentLink,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return !pack ? (
    <div className={cn(style.banner, className)}>
      <div className={cn(style.top)}>
        <ProgressiveImage {...background} className={cn(style.bg)} />
        <img {...logo} className={cn(style.logo)} />
        <ProgressiveImage {...art} className={cn(style.art)} />
        <ProgressiveImage {...mobileart} className={cn(style.mobileArt)} />
      </div>
      <div className={cn(style.bottom)}>
        <BannerLinks {...{ links }} />
      </div>
    </div>
  ) : (
    <div className={cn(style.pack)}>
      <div className={cn(style.left)}>
        <ProgressiveImage {...background} className={cn(style.bg)} />
        <img {...logo} className={cn(style.logo)} />
        <ProgressiveImage {...art} className={cn(style.art)} />
      </div>
      {withblendguide ? (
        <div className={cn(style.blendguide)}>
          <BlendGuide blendguideImage={blendguide} />
        </div>
      ) : null}
      {currentLink ? (
        <Anchor className={cn(style.currentLink)} href={currentLink.href}>
          {currentLink.label}
        </Anchor>
      ) : null}
    </div>
  );
};
