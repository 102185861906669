import React from "react";
import { Navigation } from "../Menu";
import Anchor from "@atom/Anchor";
import style from "./style.scss";
import cn from "classnames";

export default ({ navi, copyright, links }) => (
  <div className={cn(style.footer)}>
    <Navigation {...navi} className={cn(style.navigation)} footer />
    <div className={cn(style.bottom)}>
      <div className={cn(style.copyright)}>{copyright}</div>
      <div className={cn(style.links)}>
        {links.map(({ label, href }, i) => (
          <Anchor key={i} href={href}>
            {label}
          </Anchor>
        ))}
      </div>
    </div>
  </div>
);
