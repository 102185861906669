import React, { useEffect, useState } from "react";
import Api from "@api";
import Loading from "@atom/Loading";
import Banner from "@molecule/Banner";
import Countdown from "@molecule/Countdown";

export default () => {
  const [featured, setFeatured] = useState({});

  useEffect(() => {
    Api.getFeatured().then((e) => setFeatured(e));
  }, []);

  return featured.art ? (
    <>
      <Banner
        links={[
          {
            item: "buypacks",
            link: featured["url"],
            subtext: featured["url"] ? null : "Coming Soon!",
          },
          "marketplace",
          "unpack",
          "blend",
        ]}
        {...featured}
      />
      <Countdown
        subtitle={"Happening in"}
        end={"Now Available!"}
        endDate={featured["enddate"]}
        link={featured["url"]}
        title={featured["title"]}
      />
    </>
  ) : (
    <Loading />
  );
};
