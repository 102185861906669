import axios from "axios";
import "regenerator-runtime";
import { decode as base64_decode, encode as base64_encode } from "base-64";

// let url = "https://api.stage.binxtv.io/";
let url = "http://127.0.0.1:3000/";

if (process.env.BUILD_FOR == "stage") {
  url = "https://api.stage.binxtv.io/";
}

if (process.env.BUILD_FOR == "live") {
  url = "https://api.binxtv.io/"; //touch //retouch
}


const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "Accept-Language": "en_US",
  "Access-Control-Allow-Origin": "*",
};

const request = axios.create({
  baseURL: url,
  headers,
});

export default {
  async login(username, password) {
    const response = await request.post("/login", {
      username,
      password: base64_encode(password),
    });
    const data = response?.data;
    return data;
  },

  async getFeatured() {
    const response = await request.get(`/featured`);
    const data = response?.data;
    return data;
  },

  async getPastPacks() {
    const response = await request.get(`/pastPacks`);
    const data = response?.data;
    return data;
  },

  async updateFeaturedPack(pack, token) {
    const response = await request.post(
      "/updateFeatured",
      { pack },
      {
        headers: { ...headers, Authorization: token },
      }
    );
    const data = response?.data;
    return data;
  },

  async uploadImages(form) {
    const response = await request.post("/upload", form);
    const data = response?.data;
    return data;
  },

  async updatePastPack(id, pack, token) {
    const response = await request.post(
      "/updatePastPack",
      { id, pack },
      { headers: { ...headers, Authorization: token } }
    );
    const data = response?.data;
    return data;
  },

  async addPastPack(pack, token) {
    const response = await request.post(
      "/addPastPack",
      { pack },
      { headers: { ...headers, Authorization: token } }
    );
    const data = response?.data;
    return data;
  },

  async deletePastPack(id, token) {
    const response = await request.post(
      "/deletePastPack",
      { id },
      { headers: { ...headers, Authorization: token } }
    );
    const data = response?.data;
    return data;
  },

  async sortPastPacks(packs, token) {
    const response = await request.post(
      "/sortPastPacks",
      { packs },
      { headers: { ...headers, Authorization: token } }
    );
    const data = response?.data;
    return data;
  },
};
