import React, { useEffect, useState } from "react";
import Anchor from "@atom/Anchor";
import Heading from "@atom/Heading";
import style from "./style.scss";
import cn from "classnames";

const Label = ({ number, label }) => (
  <div className={cn(style.label)}>
    <Heading className={cn(style.heading)}>{number}</Heading>
    <Heading type={"h5"} className={cn(style.h5)}>
      {label}
    </Heading>
  </div>
);

const calculateTimeLeft = (endDate) => {
  let today = new Date().toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
  });

  let difference = +new Date(endDate) - +new Date(today);
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

export default ({ title, subtitle, end, link, endDate }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endDate));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(endDate));
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    timerComponents.push(
      <label key={interval}>
        <Label
          number={!timeLeft[interval] ? 0 : timeLeft[interval]}
          label={interval}
        />
        {interval !== "seconds" ? <span>:</span> : null}
      </label>
    );
  });

  return (
    <div className={cn(style.countdown)}>
      <Heading type={"h2"} className={cn(style.title)}>
        {title}
      </Heading>
      <Heading type={"h5"}>{timerComponents.length ? subtitle : null}</Heading>
      <div className={cn(style.time)}>
        {timerComponents.length ? (
          timerComponents
        ) : (
          <Anchor href={link} className={style.link}>
            <Heading>{end}</Heading>
          </Anchor>
        )}
      </div>
    </div>
  );
};
