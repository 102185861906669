import React, { useEffect, useState } from "react";
import {
  closestCenter,
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Link } from "react-router-dom";
import Api from "@api";
import AddIcon from "@assets/add.svg";
import SaveIcon from "@assets/save.svg";
import Button from "@atom/Button";
import Loading from "@atom/Loading";
import SortableItem from "@molecule/PackPreview";
import style from "./style.scss";
import cn from "classnames";

const PastPack = () => {
  const adminToken = localStorage.getItem("admin_token");
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewSaveSorting, setViewSaveSorting] = useState(false);
  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  useEffect(() => {
    Api.getPastPacks().then((e) => {
      setLoading(false);
      setItems(e);
    });
  }, []);

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        if (!viewSaveSorting) setViewSaveSorting(true);
        const oldIndex = items.findIndex((i) => i.id === active.id);
        const newIndex = items.findIndex((i) => i.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  const saveSorting = () => {
    Api.sortPastPacks(items, adminToken)
      .then((_e) => {
        alert("Packs sorted!");
        setViewSaveSorting(false);
      })
      .catch(() => {
        alert("Pack not sorted!");
      });
  };

  const deletePack = (id) => {
    let text = `Press OK to delete pack`;

    if (confirm(text) == true) {
      Api.deletePastPack(id, adminToken)
        .then((e) => {
          alert("Pack deleted!");
          setItems(e);
          setViewSaveSorting(false);
        })
        .catch(() => {
          alert("Pack not deleted!");
        });
    }
  };

  return (
    <div className={cn(style.pastPack)}>
      {viewSaveSorting && (
        <div className={cn(style.saveSorting)}>
          <Button className={cn(style.move)} onClick={saveSorting}>
            <SaveIcon />
            <span>Save sorting order</span>
          </Button>
        </div>
      )}
      {!loading ? (
        items && (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              <div className={cn(style.packDisplay)}>
                <Link
                  to={"/binx-content-admin/past-packs/" + "new"}
                  className={cn(style.addPack)}
                >
                  <AddIcon />
                  Add Past Pack
                </Link>
                {items.map((val, _i) => (
                  <SortableItem key={val.id} {...val} deletePack={deletePack} />
                ))}
              </div>
            </SortableContext>
          </DndContext>
        )
      ) : (
        <Loading form />
      )}
    </div>
  );
};

export default PastPack;
