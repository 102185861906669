import React from "react";
import LoadingSVG from "@assets/loading.svg";
import logoURL from "@assets/binx-tv-logo.png";
import style from "./style.scss";
import cn from "classnames";

export default ({ form }) => (
  <div className={cn(style.loading, form && style.form)}>
    <LoadingSVG />
    <img src={logoURL} />
  </div>
);
