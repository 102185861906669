import React, { useEffect, useState } from "react";
import { Link, Route, Switch, useHistory, useLocation } from "react-router-dom";
import LogoutIcon from "@assets/log-out.svg";
import Button from "@atom/Button";
import Heading from "@atom/Heading";
import Footer from "@molecule/Footer";
import Featured from "./FeaturedPack";
import Login from "./Login";
import PastPack from "./PastPack";
import SinglePack from "./SinglePack";
import style from "./style.scss";
import cn from "classnames";

const Tabs = () => {
  let location = useLocation().pathname;

  return (
    <div className={cn(style.headersContainer)}>
      <Link to={"/binx-content-admin"}>
        <Heading
          type={"h2"}
          className={cn(
            style.header,
            location === "/binx-content-admin" && style.active
          )}
        >
          Featured
        </Heading>
      </Link>
      <Link to={"/binx-content-admin/past-packs"}>
        <Heading
          type={"h2"}
          className={cn(
            style.header,
            location.includes("/binx-content-admin/past-packs") && style.active
          )}
        >
          Past Packs
        </Heading>
      </Link>
    </div>
  );
};

const Admin = ({ footer, menu }) => {
  let history = useHistory();
  const [loggedIn, setLoggedIn] = useState(false);
  const [adminToken, setAdminToken] = useState(
    localStorage.getItem("admin_token")
  );

  useEffect(() => {
    if (adminToken) setLoggedIn(true);
  }, []);

  const logout = () => {
    localStorage.removeItem("admin_token");
    history.push("/binx-content-admin");
    setLoggedIn(false);
  };

  return (
    <div className={cn(style.container)}>
      <div className={cn(style.content)}>
        {loggedIn && (
          <div className={cn(style.logoutContainer)}>
            <Button className={cn(style.logout)} onClick={logout}>
              <LogoutIcon />
              <span>Logout</span>
            </Button>
          </div>
        )}
        {loggedIn ? (
          <div className={cn(style.loggedIn)}>
            <Tabs />
            <Switch>
              <Route
                exact
                path={"/binx-content-admin"}
                component={() => <Featured />}
              />
              <Route
                path={"/binx-content-admin/past-packs/:id+"}
                component={() => <SinglePack />}
              />
              <Route
                path={"/binx-content-admin/past-packs"}
                component={() => <PastPack />}
              />
            </Switch>
          </div>
        ) : (
          <Login {...{ setAdminToken, setLoggedIn }} />
        )}
      </div>
      <Footer {...footer} navi={menu} />
    </div>
  );
};

export default Admin;
