import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Api from "@api";
import PackForm from "@molecule/PackForm";
import style from "./style.scss";
import cn from "classnames";

const SinglePack = () => {
  let history = useHistory();
  let { id } = useParams();
  const [formValues, setFormValues] = useState([]);
  const [packId, setPackId] = useState();
  const pushURL = "/binx-content-admin/past-packs";
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id !== "new") {
      Api.getPastPacks().then((e) => {
        const index = e.findIndex((val) => val.id === id);
        if (index !== -1) {
          setFormValues(e[index]);
          setPackId(id);
        } else history.push(pushURL + "/new");
      });
    }
  }, []);

  const saveSinglePack = (finalInput, adminToken) => {
    if (packId) {
      Api.updatePastPack(packId, finalInput, adminToken)
        .then((e) => {
          if (e) {
            alert("Pack Updated!");
            history.push(pushURL);
          } else {
            alert("Pack update failed! \n Error: " + e.message);
          }

          setLoading(false);
        })
        .catch(() => {
          alert("Pack update failed!");
          setLoading(false);
        });
    } else {
      Api.addPastPack(finalInput, adminToken)
        .then((e) => {
          if (e) {
            alert("Pack Added!");
            history.push(pushURL);
          } else alert("Pack not added! \n Error: " + e.message);

          setLoading(false);
        })
        .catch(() => {
          alert("Pack not added!");
          setLoading(false);
        });
    }
  };

  return (
    <div className={cn(style.singlePack)}>
      <PackForm
        savePack={saveSinglePack}
        formValues={formValues}
        loading={loading}
        setLoading={setLoading}
      />
    </div>
  );
};

export default SinglePack;
