import React, { useEffect } from "react";
import LabeledIcon from "@atom/LabeledIcon";

const selectLink = (item, keyLink, subtext) => {
  switch (item) {
    case "buypacks":
      return {
        icon: {
          src: require("@assets/buypacks.png"),
          width: 95,
          height: 92,
        },
        link: keyLink || "#",
        text: "Buy Packs",
        subtext: subtext,
      };

    case "marketplace":
      return {
        icon: {
          src: require("@assets/marketplace.png"),
          width: 94,
          height: 92,
        },
        link: "https://wax.atomichub.io/market?collection_name=binxfeatured",
        text: "Marketplace",
      };

    case "unpack":
      return {
        icon: { src: require("@assets/3.png"), width: 94, height: 92 },
        link: "https://neftyblocks.com/c/binxfeatured/packs",
        text: "Unpack",
      };

    case "blend":
      return {
        icon: { src: require("@assets/4.png"), width: 95, height: 92 },
        link: "https://neftyblocks.com/c/binxfeatured/blends",
        text: "Blend",
      };

    default:
      return null;
  }
};

export default ({ links, pack }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return links.map((value, i) => {
    const final = selectLink(
      value.item ? value.item : value,
      Boolean(value.link) ? value.link : null,
      value.subtext || null
    );

    return <LabeledIcon {...final} key={i} smaller={!pack ? false : true} />;
  });
};
