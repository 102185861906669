import React from "react";
import style from "./style.scss";
import cn from "classnames";

export default ({
  children,
  className,
  disabled,
  onClick,
  onFocus,
  type = "button",
  ...rest
}) => (
  <button
    className={cn(style.button, className)}
    {...{ disabled, onClick, onFocus, type, ...rest }}
  >
    {children}
  </button>
);
